import React from 'react';

import LogoImage from '../../assets/eurowings-logo.svg'
import './styles.scss';

export type LogoProps = {
  link: string;
};

const Logo: React.FC<LogoProps> = ({ link }) => {
  return (
    <a className="logo-wrapper" href={link}>
      <img className="logo" src={LogoImage} alt="Eurowings logo" />
    </a>
  );
};

export default Logo;
